import App from "./App.vue";
import VueFab from "vue-float-action-button";
import Vue from "vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/assets/css/font-awesome-n.min.css";
import "@/assets/css/font-awesome.min.css";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import "@/assets/scss/global.scss";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.filter("quantity", function (quantity: number) {
  return isNaN(quantity)
    ? "-"
    : quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "MT";
});

Vue.filter("status", function (status: boolean) {
  return status ? "approved" : "pending";
});

Vue.filter("date", function (date: string) {
  return !date ? "-" : moment(new Date(date)).format("DD MMM YY");
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueFab, {
  iconType: "MaterialDesign",
});
Vue.use(VueApexCharts);
//Vue.use(MdForm);

Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
