
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  name: "App",
  components: { Header, Footer },
})
export default class App extends Vue {
  bgColor = "#096EB4";
  clickItem() {
    //
  }
  showMenu() {
    return this.$route.name !== "/login";
  }
}
