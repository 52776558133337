
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "Header",
})
export default class Header extends Vue {
  userString = localStorage.getItem("user");
  isAdmin = false;
  public data() {
    return {
      items: [
        {
          text: "CTS",
          href: "#",
        },
        {
          text: "Dashboard",
          href: "#",
        },
      ],
      bgColor: "#778899",
      position: "top-right",
    };
  }
  created() {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  visitRoute(link: string) {
    this.$router.push(link);
  }
}
