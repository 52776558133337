import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const Home = () => import("@/views/Home.vue");
const Warehouse = () => import("@/views/Warehouse.vue");
const LoadingPlan = () => import("@/views/LoadingPlan.vue");
const Instruction = () => import("@/views/Instruction.vue");
const Dispatch = () => import("@/views/Dispatch.vue");
const Receipt = () => import("@/views/Receipt.vue");
const Project = () => import("@/views/Project.vue");
const Reports = () => import("@/views/Reports.vue");
const Login = () => import("@/views/Login.vue");
const Logout = () => import("@/views/Logout.vue");
const Users = () => import("@/views/Users.vue");
const Settings = () => import("@/views/Settings.vue");
const InstructionPDF = () => import("@/views/InstructionPDF.vue");
const Error = () => import("@/views/Error.vue");

Vue.use(VueRouter);
//const userRoles = ["admin", "manager", "recipient", "dispatcher"];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/warehouse",
    name: "warehouse",
    component: Warehouse,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/project-management",
    name: "project-management",
    component: Project,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/requisition",
    name: "requisition",
    component: LoadingPlan,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/loading-plan",
    name: "loading-plan",
    component: LoadingPlan,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/loading-plan/pending",
    name: "pending-loading-plan",
    component: LoadingPlan,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/instruction",
    name: "instruction",
    component: Instruction,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/instruction/download",
    name: "InstructionPDF",
    component: InstructionPDF,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/dispatch",
    name: "dispatch",
    component: Dispatch,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/dispatch/pending",
    name: "pending-dispatch",
    component: Dispatch,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/receipt",
    name: "receipt",
    component: Receipt,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      authorize: [],
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
      authorize: ["admin"],
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", redirect: "/error" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const isHiddenForAuth = to.matched.some((record) => record.meta.hideForAuth);
  const expiration = localStorage.getItem("expiration");
  const userString = localStorage.getItem("user");
  const hasToken = localStorage.getItem("token") != null;
  const isExpired = expiration == null || new Date(expiration) <= new Date();
  let user = {
    id: "",
    userName: "",
    email: "",
    password: "",
    phoneNumber: "",
    roles: [""],
  };
  if (userString != null) {
    user = JSON.parse(userString);
  }
  const isLoggedIn = hasToken && !isExpired && userString != null;
  if (isHiddenForAuth && isLoggedIn) {
    return next({ name: "index" });
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !isLoggedIn) {
    return next({ name: "login" });
  }
  if (requiresAuth && isLoggedIn && to.meta != undefined) {
    const authorize = to.meta.authorize;
    const userRoles = user.roles;
    if (
      authorize != undefined &&
      authorize.length &&
      authorize.some((r: any) => userRoles.indexOf(r) < 0)
    ) {
      return next({ name: "index" });
    }
  }
  return next();
});

export default router;
